/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kavárna MANETKA - Stálé menu"} description={"Stálé menu nabízené kavárnou Manetka"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1d4ghsj css-42e4bw js-anim  --anim3 --full" anim={"3"} name={"menu_uvod"} style={{"paddingTop":202,"marginBottom":1,"paddingBottom":251}} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/34102/0d6551d3a0bf4b17be87823925937722_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/34102/0d6551d3a0bf4b17be87823925937722_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/34102/0d6551d3a0bf4b17be87823925937722_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/34102/0d6551d3a0bf4b17be87823925937722_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/34102/0d6551d3a0bf4b17be87823925937722_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/34102/0d6551d3a0bf4b17be87823925937722_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/34102/0d6551d3a0bf4b17be87823925937722_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/34102/0d6551d3a0bf4b17be87823925937722_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--shape4 --left" style={{"maxWidth":1358,"paddingTop":31,"paddingBottom":32,"backgroundColor":"rgba(0,0,0,0.35)"}}>
              
              <Title className="title-box fs--102 title-box--invert" content={"<span style=\"color: var(--white)\">Stálé menu</span>"}>
              </Title>

              <Text className="text-box text-box--invert" style={{"maxWidth":1144,"marginTop":20}} content={"Vše, co nabízíme denně, jsme pro Vás shrnuli do stálého menu. <br>Každý den Vás však překvapíme i něčím navíc ;-)<br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape5 btn-box--shadow5 fs--20 w--400 swpf--uppercase ls--002 lh--18" use={"page"} href={"/menu#jidelak_napojak"} content={"K meníčku"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"menu_info"} style={{"paddingTop":87,"paddingBottom":81}} layout={"l2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" style={{"maxWidth":1494}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/aebd6fb2601543a28d89f03da3bcdd90.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":130}} srcSet={""} svgColor={"rgba(0,0,0,1)"} RootClassName={"column__icon"}>
              </Image>

              <Text className="text-box" style={{"paddingTop":3}} content={"Naše kavárna nabízí víc než jen výbornou kávu. Ochutnejte rozmanité příchutě našich pohárů, různé druhy a tvary dezertů včetně domácích, čerstvé dorty, lahodné zákusky, voňavou pizzu či jiné občerstvení. "}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/d996d9080be947789a2ec065ff964959.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":132}} srcSet={""} svgColor={"rgba(0,0,0,1)"} RootClassName={"column__icon"}>
              </Image>

              <Text className="text-box" style={{"marginTop":48}} content={"Z nabídky nápojů můžeme nabídnout samozřejmě nealkoholické nápoje studené i teplé, doporučujeme ochutnat naše moravské víno dovezené z oblasti Mikulova a pro pivaře se najde kvalitně načepovaný Prazdroj."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Hotel/hotel-12.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":128}} srcSet={""} svgColor={"rgba(0,0,0,1)"} RootClassName={"column__icon"}>
              </Image>

              <Text className="text-box" style={{"marginTop":38}} content={"Veškeré občerstvení, které nabízíme denně, jsme pro Vás shrnuli do stálého menu. Každý den Vás však překvapíme nejen novou čerstvou polévkou, ale i něčím navíc ;-)"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="css-ssxxxt --center --parallax" name={"menu_pozadi"} style={{"marginTop":0,"paddingTop":102,"marginBottom":0,"paddingBottom":695}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/34102/43df0971c06d4e58bc7241fa19824f66_s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/34102/43df0971c06d4e58bc7241fa19824f66_s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/34102/43df0971c06d4e58bc7241fa19824f66_s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/34102/43df0971c06d4e58bc7241fa19824f66_s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/34102/43df0971c06d4e58bc7241fa19824f66_s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/34102/43df0971c06d4e58bc7241fa19824f66_s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/34102/43df0971c06d4e58bc7241fa19824f66_s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/34102/43df0971c06d4e58bc7241fa19824f66_s=3000x_.JPG);
    }
  
    `}>
        </Column>


        <Column className="pb--60" name={"jidelak_napojak"} style={{"paddingTop":47,"backgroundColor":"rgba(128, 128, 128, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 pt--30 flex--top" style={{"maxWidth":1416}} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper className="--hvr5 --left">
              
              <Image className="--shape4 --shadow4" alt={"menu 1"} src={"https://cdn.swbpg.com/t/34102/84f64cf8636f4c73ae799421be1005ad_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"paddingTop":8}} srcSet={"https://cdn.swbpg.com/t/34102/84f64cf8636f4c73ae799421be1005ad_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/84f64cf8636f4c73ae799421be1005ad_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/84f64cf8636f4c73ae799421be1005ad_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/84f64cf8636f4c73ae799421be1005ad_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5 --left">
              
              <Image className="--shape4 --shadow4" alt={"menu 2"} src={"https://cdn.swbpg.com/t/34102/a6c989b19c194c5096b7bfa87e5b86d3_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"marginTop":0,"paddingTop":0}} srcSet={"https://cdn.swbpg.com/t/34102/a6c989b19c194c5096b7bfa87e5b86d3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/a6c989b19c194c5096b7bfa87e5b86d3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/a6c989b19c194c5096b7bfa87e5b86d3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/a6c989b19c194c5096b7bfa87e5b86d3_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"vino_info"} style={{"paddingTop":96,"paddingBottom":90}} layout={"l2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"paddingTop":0,"paddingBottom":7}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Vinarsvi/vinarstvi-22.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":138}} srcSet={""} svgColor={"rgba(0,0,0,1)"} RootClassName={"column__icon"}>
              </Image>

              <Title className="title-box" content={"<span style=\"color: var(--black);\">Mikulovská vinařská oblast \"Klentnice\"</span>"}>
              </Title>

              <Text className="text-box fs--20 w--400 ls--002 lh--18" content={"Naše vína pocházejí z nejvýše položené vinařské obce v srdci Pálavy - Klentnice, z Vinařství pod Hradem. Jedná se o nejznámější moravskou Mikulovskou vinařskou oblast u Horních Věstonic."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column name={"menu_galerie"} style={{"paddingTop":95,"paddingBottom":92,"backgroundColor":"rgba(128, 128, 128, 1)"}} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--4" style={{"maxWidth":2138}} columns={"4"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4 --shadow4" alt={"domácí kremrole"} src={"https://cdn.swbpg.com/t/34102/20612738f872424f845e49bc57a2d873_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/20612738f872424f845e49bc57a2d873_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/20612738f872424f845e49bc57a2d873_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/20612738f872424f845e49bc57a2d873_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/20612738f872424f845e49bc57a2d873_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4 --shadow4" alt={"čokoládový dort"} src={"https://cdn.swbpg.com/t/34102/bbdf7b0344304b0da562b70be9eb69ae_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/bbdf7b0344304b0da562b70be9eb69ae_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/bbdf7b0344304b0da562b70be9eb69ae_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/bbdf7b0344304b0da562b70be9eb69ae_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/bbdf7b0344304b0da562b70be9eb69ae_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4 --shadow4" alt={"domácí pohár z vaječného likéru"} src={"https://cdn.swbpg.com/t/34102/ed181ba27b3849cebb116f864405dedc_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/ed181ba27b3849cebb116f864405dedc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/ed181ba27b3849cebb116f864405dedc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/ed181ba27b3849cebb116f864405dedc_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/ed181ba27b3849cebb116f864405dedc_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4 --shadow4" alt={"žloutkové věnečky"} src={"https://cdn.swbpg.com/t/34102/e521b985b1a140698dbaec837bc4eb35_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/e521b985b1a140698dbaec837bc4eb35_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/e521b985b1a140698dbaec837bc4eb35_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/e521b985b1a140698dbaec837bc4eb35_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/e521b985b1a140698dbaec837bc4eb35_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":104,"paddingBottom":103}} name={"paticka"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" style={{"maxWidth":1406,"paddingLeft":0,"marginBottom":0}} columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--20 w--400 ls--002 lh--14" content={"<span style=\"font-weight: bold; color: var(--black);\">WEB</span>"}>
              </Title>

              <Text className="text-box fs--18 w--400 ls--002 lh--16" style={{"marginTop":14,"marginBottom":0,"paddingBottom":30}} content={"<span style=\"color: var(--black);\"><span style=\"text-decoration-line: underline;\"><a href=\"/#bcckfdbfejb\" style=\"\">O nás</a></span><a href=\"/menu#menu_uvod\"><br>Stálé menu<br></a><a href=\"/akce#j40igf0u8gp\">Akce</a><a href=\"/menu#menu_uvod\" style=\"\"><br></a><a href=\"/salonek#salonek_uvod\" style=\"\">Salónek</a><a href=\"/menu#menu_uvod\"><br></a><a href=\"/kontakt#kontakty\" style=\"\">Kontakt</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--20 w--400 ls--002 lh--14" content={"<span style=\"font-weight: bold; color: var(--black);\">KAVÁRNA A CUKRÁRNA MANETKA</span>"}>
              </Title>

              <Text className="text-box fs--18 w--400 ls--002 lh--16" style={{"maxWidth":396,"marginTop":13,"paddingRight":0,"paddingBottom":0}} content={"Manětínská 30, Plzeň<br>tel: 773 300 833, email: kavarna@manetka.cz<br><span style=\"text-decoration-line: underline; font-weight: bold; color: var(--black);\"><a href=\"https://www.google.com/maps/place/Manetka+cafe/@49.7800782,13.3664327,3a,90y,117.55h,89.06t/data=!3m7!1e1!3m5!1sAF1QipPbAylCirgJw9XZmzsz7O3ipPU8vZNmJViK7vnr!2e10!3e12!7i11000!8i5500!4m9!3m8!1s0x470af33bb34100a5:0x17c49104135d9431!8m2!3d49.7798029!4d13.3665938!10e5!14m1!1BCgIgARICCAI!16s%2Fg%2F11l2m45152?entry=ttu\" target=\"_blank\" style=\"\">VIRTUÁLNÍ PROHLÍDKA KAVÁRNY</a></span><br><br><span style=\"font-weight: bold;\">OTEVÍRACÍ DOBA KAVÁRNY</span><br>Po - Ne 10:00 - 21:00<br>"}>
              </Text>

              <Image style={{"maxWidth":124,"marginTop":33}} alt={""} src={"https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=860x_.png"} use={"page"} href={"/#bcckfdbfejb"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=350x_.png 350w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=660x_.png 660w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=860x_.png 860w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=1400x_.png 1400w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=2000x_.png 2000w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--20 w--400 ls--002 lh--14" content={"<span style=\"font-weight: bold; color: var(--black);\">SLEDUJ NÁS</span>"}>
              </Title>

              <Image style={{"maxWidth":64,"marginTop":31}} alt={"Facebook"} src={"https://cdn.swbpg.com/t/34102/556bd886db6346cc9a4b6ae94cde69b1_s=350x_.png"} url={"https://www.facebook.com/Manetkacafe"} use={"url"} href={"https://www.facebook.com/Manetkacafe"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/556bd886db6346cc9a4b6ae94cde69b1_s=350x_.png 350w"} lightbox={false}>
              </Image>

              <Image style={{"maxWidth":64,"marginTop":35}} alt={"Instagram"} src={"https://cdn.swbpg.com/t/34102/9fa843421abe4a449de7c1ad3493add4_s=350x_.png"} url={"https://www.instagram.com/manetka_cafe/"} use={"url"} href={"https://www.instagram.com/manetka_cafe/"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/9fa843421abe4a449de7c1ad3493add4_s=350x_.png 350w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}